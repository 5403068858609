<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showContractForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="contractTitle"
              placeholder="Title"
              label="Title"
              :rules="[v => !!v || 'Contract title is required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="contractAmount"
              placeholder="Contract Amount"
              label="Amount"
              type="number"
              :rules="[v => !isNaN(parseFloat(v)) && v >= 0 || 'Amount is positive number']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="unitRate"
              placeholder="Unit Rate"
              label="Unit Rate"
              type="number"
              :rules="[v => !isNaN(parseFloat(v)) && v >= 0 || 'Unit rate is positive number']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="discountedUnits"
              placeholder="Discounted Units"
              label="Discounted Units"
              type="number"
              :rules="[v => !isNaN(parseInt(v)) && v >= 0 || 'Discounted units is positive number']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
             <v-select
              :items="['INR', 'USD']"
              label="Currency"
              :rules="[v => !!v || 'Currency is required']"
              v-model="currency"
              class="pa-0 mb-1"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-select
              :items="['Perunit',  'Monthly', 'Yearly']"
              label="Engagement Mode"
              v-model="engagementMode"
              :rules="[v => !!v || 'Engagement Mode is required']"
              class="pa-0 ma-0"
            ></v-select>
          </v-col>

          <v-col
          >
            <v-row class="mb-2">
              <label> Select Start and End date
              </label>
            </v-row>
            <v-row justify="space-around">
              <v-date-picker
                v-model="startDate"
                :show-current="false"
                :rules="[v => !!v || 'Start date is required']"
              ></v-date-picker>
              <v-date-picker
                v-model="endDate"
                :show-current="false"
                :rules="[v => !!v || 'End date is required']"
              ></v-date-picker>
            </v-row>
          </v-col>

          <v-col
            offset-md="1"
            cols="12"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="saveContract">
              Add Contract
            </v-btn>
            <v-btn
              type="reset"
              class="mx-2"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

import { listClient, addClientContract } from "../../../services/apiCall.js";

export default {
  setup() {
    const valid = ref(true)
    const alertMsg = ref('')
    const alertType = ref('success')
    const showAlert = ref(false)
    const showContractForm = ref(false)
    const selectedClient = ref('')
    const clientNameList = ref([])
    const contractTitle = ref('')
    const contractAmount = ref()
    const unitRate = ref()
    const discountedUnits = ref()
    const currency = ref('')
    const engagementMode = ref('')
    const startDate = ref()
    const endDate = ref()
    const contractDocs = ref()
    const submitDisabled = ref(false)

    return {
      valid,
      alertMsg,
      alertType,
      showAlert,
      showContractForm,
      selectedClient,
      clientNameList,
      contractTitle,
      contractAmount,
      unitRate,
      discountedUnits,
      currency,
      engagementMode,
      startDate,
      endDate,
      contractDocs,
      submitDisabled,
      icons: {
        mdiClose,
      },

      listClient,
      addClientContract
    }
  },
  mounted:function(){
    this.init();
  },
    methods: {
    init() {
      // List clients
      this.listClient({ include_address: true })
        .then(async (res) => {
          //console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
        });
    },
    ClientNameListChanged() {
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.showContractForm = true;
      } else {
        this.showContractForm = false;
      }
    },
    async saveContract() {
      await this.$refs.form.validate();

      if (!this.valid) return;

      if (!this.startDate || !this.startDate.length) {
        this.alertType = "error";
        this.alertMsg = 'Start date is required';
        return;
      }

      if (!this.endDate || !this.endDate.length) {
        this.alertType = "error";
        this.alertMsg = 'End date is required';
        return;
      }

      this.submitDisabled = true;
      this.addClientContract({
        client_id: this.selectedClient.id,
        contract_title: this.contractTitle,
        contract_amount: this.contractAmount,
        unit_rate: this.unitRate,
        discounted_units: this.discountedUnits,
        currency: this.currency,
        engagement_mode: this.engagementMode,
        start_date: this.startDate,
        end_date: this.endDate,
      })
        .then(async (res) => {
          console.log('add contract called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = 'Client contract added successfully';
            this.resetForm();
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.alertType = "error";
          this.alertMsg = "Client contract Failed" + ": " + err.toString();
        });
    },
    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
      this.startDate = null;
      this.endDate = null;
      this.showContractForm = false;
    },
  }
}
</script>
