<template>
  <v-card id="tox-client-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <tox-client-create ></tox-client-create>
      </v-tab-item>

      <v-tab-item>
        <add-client-address></add-client-address>
      </v-tab-item>

      <v-tab-item>
        <add-client-contact-person></add-client-contact-person>
      </v-tab-item>

      <v-tab-item>
        <add-client-contract></add-client-contract>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

import ToxClientCreate from './ToxClientCreate.vue'
import AddClientAddress from './AddClientAddress.vue'
import AddClientContactPerson from './AddClientContactPerson.vue'
import AddClientContract from './AddClientContract.vue'

export default {
  components: {
    ToxClientCreate,
    AddClientAddress,
    AddClientContactPerson,
    AddClientContract,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Client', icon: mdiAccountOutline },
      { title: 'Address', icon: mdiLockOpenOutline },
      { title: 'Add Contact Person', icon: mdiInformationOutline },
      { title: 'Add Contract', icon: mdiInformationOutline },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>
