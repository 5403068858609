<template>
  <v-card
    flat
    class="pa-0 ma-0"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showAddressForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <label for="addressLines">Address</label>
          </v-col>

          <v-col
            cols="12"
            md="10"
          >
            <v-textarea
              id="addressLines"
              v-model="addressLines"
              placeholder="Address"
              outlined
              :rules="[v => !!v || 'Client address is required']"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <label for="gstn">City</label>
          </v-col>

          <v-col
            cols="12"
            md="10"
          >
            <v-text-field
              id="city"
              v-model="city"
              outlined
              dense
              placeholder="City"
              hide-details
            ></v-text-field>
          </v-col> 
          <v-col
            cols="12"
            md="2"
          >
            <label>State</label>
          </v-col>
          <v-col
            cols="12"
            md="10"
          >
            <v-select
              v-model="selectedState"
              :items="stateList"
              item-text="state_name"
              label="Select state"
              persistent-hint
              return-object
              single-line
              class="pa-0 ma-0"
              :rules="[v => !!v || 'State name is required']"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <label>GSTN</label>
          </v-col>
          <v-col
            cols="12"
            md="10"
          >
            <v-text-field
              id="addressGSTN"
              v-model="addressGSTN"
              outlined
              dense
              placeholder="GSTN"
              hide-details
              :rules="[v => !!v || 'GSTN is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="saveAddress">
              Save
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="showAddressForm=false, showAddressCard=true">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-row v-if="showAddressCard">
      <v-col
        cols="12"
        md="6"
        v-for="address in selectedClient.addresses"
        :key="address.id"
      >
        <v-card
          elevation="7"
          class="mx-auto"
        >
          <v-card-text class="text--primary">
            {{ address.address_lines }}<br/>
            City: {{ address.city }}<br/>
            State: {{ address.state_name }}<br/>
            Country: {{ address.country }}<br/>
            GSTN: {{ address.gstn }}<br/>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text @click="updateAddress(address)">
              Update
            </v-btn>
            <v-btn color="orange" text @click="deleteAddress(address)">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn color="primary" @click="onAddNewAddress()">
          Add New Address
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="my-2"></v-divider>
    
    <v-alert
      v-model="showAlert"
      :type="alertType"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

import { listClient, listState, addClientAddress, deleteClientAddress } from "../../../services/apiCall.js";

export default {
  setup() {
    const valid = ref(true)
    const alertMsg = ref('')
    const alertType = ref('success')
    const showAlert = ref(false)
    const showAddressForm = ref(false)
    const showAddressCard = ref(false)
    const selectedAddress = ref(null)
    const selectedClient = ref('')
    const selectedAddressType = ref('Other')
    const addressGSTN = ref('')
    const selectedState = ref('')
    const clientNameList = ref([])
    const stateList = ref([])
    const city = ref('')
    const addressLines = ref('')
    const submitDisabled = ref(false)

    return {
      alertMsg,
      alertType,
      showAlert,
      showAddressForm,
      showAddressCard,
      selectedClient,
      selectedAddress,
      selectedAddressType,
      clientNameList,
      selectedState,
      stateList,
      city,
      addressLines,
      addressGSTN,
      submitDisabled,
      valid,
      icons: {
        mdiClose,
      },

      listClient,
      listState,
      addClientAddress,
      deleteClientAddress,
    }
  },
  mounted:function(){
    this.init();
  },
  methods: {
    init() {
      // List clients
      this.listClient({ include_address: true })
        .then(async (res) => {
          //console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "List client failed" + ": " + err.toString());
        });

      //List states
      this.listState()
        .then(async (res) => {
          //console.log('list state called:', res);
          if (res.data.status === 0) {
            this.stateList = res.data.result.state_list;
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "List state failed" + ": " + err.toString());
        });
    },
    showAlertMessage(alertType, msg) {
      this.showAlert = true;
      this.alertType = alertType;
      this.alertMsg = msg;
    },

    ClientNameListChanged() {
      this.selectedAddress = null;
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.showAddressCard = true;
        this.showAddressForm = false;
      } else {
        this.showAddressCard = false;
        this.showAddressForm = false;
      }
    },
    async saveAddress() {
      await this.$refs.form.validate();

      if (!this.valid) return;

      this.submitDisabled = true;
      this.addClientAddress({
        client_id: this.selectedClient.id,
        state_id: this.selectedState.id,
        city: this.city,
        address_type: this.selectedAddressType,
        address_lines: this.addressLines,
        address_id: this.selectedAddress ? this.selectedAddress.id : -1,
        gstn: this.addressGSTN,
      })
        .then(async (res) => {
          //console.log('add address called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlertMessage("success", 'Client address saved successfully');
            this.resetForm();
            this.init();
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlertMessage("error", "Client address Failed" + ": " + err.toString());
        });

    },

    onAddNewAddress() {
      this.showAddressForm = true;
      this.showAddressCard = false;
      this.addressGSTN = this.selectedClient.gstn;
    },

    updateAddress(address) {
      this.selectedAddress = address;
      this.addressLines = address.address_lines;
      this.city = address.city;
      this.selectedState = this.stateList.filter((y) => y.id === address.state_id)[0];
      this.addressGSTN = address.gstn;
      this.showAddressCard = false;
      this.showAddressForm = true;
    },

    deleteAddress(address) {
      this.submitDisabled = true;
      this.deleteClientAddress({
        client_id: this.selectedClient.id,
        address_id: address.id,
      })
        .then(async (res) => {
          //console.log('delete address called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlertMessage("success", "Client address deleted successfully");
            this.selectedClient.addresses = this.selectedClient.addresses.filter((y) => y.id !== address.id);
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlertMessage("error", "Delete client address Failed" + ": " + err.toString());
        });
    },

    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
      this.showAddressForm = false;
      this.selectedAddress = null;
    }
  }
}
</script>
