<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showPersonForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="personName"
              placeholder="Name of person"
              label="Name"
              :rules="[v => !!v || 'Name of person is required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="contactPhone"
              placeholder="Phone"
              label="Phone"
              :rules="[v => !!v || 'Phone is required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="contactEmail"
              placeholder="Email"
              label="Email"
              :rules="[v => !!v || 'Email is required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="designation"
              placeholder="Designation"
              label="Designation"
              :rules="[v => !!v || 'Designation is required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <label for="contactPurpose">Purpose</label>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-radio-group
              v-model="contactPurpose"
              row
              class="pa-0 ma-0"
              :rules="[v => !!v || 'Contact purpose is required']"
            >
              <v-radio
                label="Invoice"
                value="Invoice"
              ></v-radio>
              <v-radio
                label="Report"
                value="Report"
              ></v-radio>
              <v-radio
                label="Other"
                value="Other"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="savePersonDetails">
              Save
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn color="primary"  @click="showPersonForm=false,showPersonCard=true">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-row v-if="showPersonCard">
      <v-col
        cols="12"
        md="6"
        v-for="person in selectedClient.contact_persons"
        :key="person.id"
      >
        <v-card
          elevation="7"
          class="mx-auto"
        >
          <v-card-text class="text--primary">
            Name: {{ person.person_name }}<br/>
            Phone: {{ person.contact_phone }}<br/>
            Email: {{ person.contact_email }}<br/>
            Designation: {{ person.designation }}<br/>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text @click="updatePerson(person)">
              Update
            </v-btn>
            <v-btn color="orange" text @click="deletePerson(person)">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn color="primary" @click="showPersonForm=true, showPersonCard=false">
          Add New Person
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-model="showAlert"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

import { listClient, addClientContactPerson, deleteClientContactPerson } from "../../../services/apiCall.js";

export default {
  setup() {
    const valid = ref(true)
    const alertMsg = ref('')
    const showAlert = ref(false)
    const alertType = ref('success')
    const submitDisabled = ref(false)
    const selectedClient = ref('')
    const clientNameList = ref([])
    const showPersonForm = ref(false)
    const personName = ref('')
    const contactPhone = ref('')
    const contactEmail = ref('')
    const designation = ref('')
    const contactPurpose = ref('')
    const showPersonCard = ref(false)
    const selectedPerson = ref(null)

    return {
      valid,
      alertMsg,
      alertType,
      showAlert,
      selectedClient,
      clientNameList,
      showPersonForm,
      showPersonCard,
      personName,
      contactPhone,
      contactEmail,
      designation,
      contactPurpose,
      submitDisabled,
      icons: {
        mdiClose,
      },
      listClient,
      addClientContactPerson,
      deleteClientContactPerson,

    }
  },
  mounted:function(){
    this.init();
  },

  methods: {
    init() {
      // List clients
      this.listClient({ include_contact_person: true })
        .then(async (res) => {
          //console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          showAlertMessage("error", "List client failed" + ": " + err.toString());
        });
    },

    showAlertMessage(alertType, msg) {
      this.showAlert = true;
      this.alertType = alertType;
      this.alertMsg = msg;
    },

    ClientNameListChanged() {
      this.selectedPerson = null;
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.showPersonCard = true;
      } else {
        this.showPersonCard = false;
      }
    },

    async savePersonDetails() {
      await this.$refs.form.validate();

      if (!this.valid) return;

      this.submitDisabled = true;
      this.addClientContactPerson({
        client_id: this.selectedClient.id,
        person_name: this.personName,
        contact_phone: this.contactPhone,
        contact_email: this.contactEmail,
        designation: this.designation,
        contact_purpose: this.contactPurpose,
        person_id: this.selectedPerson?this.selectedPerson.id:-1,
      })
        .then(async (res) => {
          //console.log('add person called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlertMessage("success", res.data.result.message);
            this.selectedClient.contact_persons = this.selectedClient.contact_persons.filter((y) => y.id !== res.data.result.contact_person.id);
            this.selectedClient.contact_persons.push(res.data.result.contact_person);
            this.selectedPerson = null;
            this.resetForm();
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlertMessage("error", "Contact person addition Failed" + ": " + err.toString());
        });
    },

    updatePerson(person) {
      this.selectedPerson = person;
      this.personName = person.person_name;
      this.contactPhone = person.contact_phone;
      this.contactEmail = person.contact_email;
      this.designation = person.designation;
      this.contactPurpose = person.contact_purpose;

      this.showPersonCard = false;
      this.showPersonForm = true;
    },

    deletePerson(person) {
      this.submitDisabled = true;
      this.deleteClientContactPerson({
        client_id: this.selectedClient.id,
        person_id: person.id,
      })
        .then(async (res) => {
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlertMessage("success", "Client contact person deleted successfully");
            this.selectedClient.contact_persons = this.selectedClient.contact_persons.filter((y) => y.id !== person.id);
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlertMessage("error", "Delete contact person Failed" + ": " + err.toString());
        });
    },

    resetForm() {
      this.$refs.form.reset();
      //this.selectedClient = null;
      this.selectedPerson = null;
      this.showPersonForm = false;
      this.showPersonCard = true;
    },
  }
}
</script>
