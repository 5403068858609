<template>
 <v-card
    flat
    class="pa-0 ma-0"
  >
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showClientList"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client to update"
            required
            return-object
            @change="ClientNameListChanged"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
  <v-card
      flat
      class="pa-2 ma-2"
      v-if="showClientForm"
    >
    <v-form>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <label for="organizationName">Organization Name</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            id="organizationName"
            v-model="organizationName"
            outlined
            dense
            placeholder="Organization Name"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <label for="shortName">Short Name</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            id="shortName"
            v-model="shortName"
            outlined
            dense
            placeholder="Short Name"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <label for="gstn">GST Number</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            id="gstn"
            v-model="gstn"
            outlined
            dense
            placeholder="GSTN"
            hide-details
          ></v-text-field>
        </v-col>  

        <v-col
          offset-md="8"
          cols="12"
          md="2"
        >
          <v-btn :disabled="submitDisabled" color="primary" @click="saveClient">
            Save
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn :disabled="submitDisabled" color="primary" @click="showClientForm=false, showClientList=true">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  <v-card
    flat
    class="pa-2 ma-2"
    v-if="!showClientForm"
  >
    <v-btn color="primary" @click="onAddNewClient()">
      Add New Client
    </v-btn>
  </v-card>
  <v-divider class="my-2"></v-divider>
  
  <v-alert
    :type="alertType"
    v-model="showAlert"
    dismissible
  > 
    <span v-html="alertMsg"></span>
  </v-alert>

  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

import { createClient, listClient } from "../../../services/apiCall.js";

export default {
  setup() {
    const organizationName = ref('')
    const shortName = ref('')
    const gstn = ref('')
    const showAlert = ref(false)
    const alertMsg = ref('')
    const alertType = ref('success')
    const submitDisabled = ref(false)
    const showClientForm = ref(false)
    const clientNameList = ref([])
    const selectedClient = ref('')
    const showClientList = ref(true)

    return {
      organizationName,
      shortName,
      gstn,
      alertMsg,
      showAlert,
      alertType,
      submitDisabled,
      showClientForm,
      clientNameList,
      selectedClient,
      showClientList,
      icons: {
        mdiClose,
      },

      createClient,
      listClient,
    }
  },
  mounted:function(){
    this.init();
  },
  methods: {
    init() {
      // List clients
      this.listClient()
        .then(async (res) => {
          //console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "List client failed" + ": " + err.toString());
        });
    },
    showAlertMessage(alertType, msg) {
      this.showAlert = true;
      this.alertType = alertType;
      this.alertMsg = msg;
    },
    ClientNameListChanged() {
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.showClientForm = true;
        this.organizationName = this.selectedClient.organization_name;
        this.shortName = this.selectedClient.short_name;
        this.gstn = this.selectedClient.gstn;
      } else {
        this.showClientForm = false;
      }
    },
    saveClient() {
      if (this.organizationName.trim() === "") {
        this.showAlertMessage("error", "Organization name is empty provided, Please provide name.");
        return;
      }
      if (this.shortName.trim() === "") {
        this.showAlertMessage("error", "Short name is empty provided, Please provide short name.");
        return;
      }
      if (this.gstn.trim() === "") {
        this.showAlertMessage("error", "GST number not provided. Please provide GST number");
        return;
      }

      this.submitDisabled = true; 
      this.createClient({
        "organization_name": this.organizationName,
        "short_name": this.shortName,
        "gstn": this.gstn,
        "client_id": this.selectedClient?this.selectedClient.id:-1,
      })
        .then(async (res) => {
          //console.log('create client called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.organizationName = '';
            this.shortName = '';
            this.gstn = '';
            this.showClientList = true;
            this.showClientForm = false;
            this.selectedClient = null;
            this.showAlertMessage("success", 'Client saved successfully');
            this.init();
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlertMessage("error", "Client saving Failed" + ": " + err.toString());
        });
    },

    onAddNewClient() {
      this.showClientForm = true;
      this.showClientList = false;
      this.selectedClient = null;
    }
  }
}
</script>
